<template>
  <el-dialog
    append-to-body
    width="600px"
    top="3vh"
    :title="`${isChange ? '查看' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      class="elFormDom"
      label-width="140px"
      label-position="right"
      :model="form.data"
      :rules="form.rules"
      :size="subUnitSize"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <!--  只有总公司账号才有权限选择开票公司,不可修改 -->
          <el-form-item v-if="this.login_icId == 0" label="开票单位：" prop="invoice_company_id" class="invoice_company">
            <el-select v-model="form.data.invoice_company_id" placeholder="请选择" clearable filterable :disabled="isChange" @change="selInvoiceChange">
              <el-option
                v-for="item in invoiceCompanyOpt"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="name" label="基数档次：">
            <el-input
              v-model="form.data.name"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- 养老保险 -->
        <el-col :span="24">
          <el-form-item prop="endowment_base" label="养老保险基数：">
            <el-input
              v-model="form.data.endowment_base"
              clearable
              placeholder="请输入"
              ><span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="endowment_company_rate" label="单位占比：">
            <el-input
              v-model="form.data.endowment_company_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="endowment_employees_rate" label="员工占比：">
            <el-input
              v-model="form.data.endowment_employees_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- 医疗保险基数 -->
        <el-col :span="24">
          <el-form-item prop="medical_base" label="医疗保险基数：">
            <el-input
              v-model="form.data.medical_base"
              clearable
              placeholder="请输入"
              @change="blurInput"
              ><span slot="suffix">元</span></el-input
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="medical_company_rate" label="单位占比：">
            <el-input
              v-model="form.data.medical_company_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="medical_employees_rate" label="员工占比：">
            <el-input
              v-model="form.data.medical_employees_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- 生育保险基数 -->
        <el-col :span="24">
          <el-form-item prop="birth_base" label="生育保险基数：">
            <el-input
              v-model="form.data.birth_base"
              clearable
              placeholder="请输入"
              ><span slot="suffix">元</span></el-input
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="birth_company_rate" label="单位占比：">
            <el-input
              v-model="form.data.birth_company_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="birth_employees_rate" label="员工占比：">
            <el-input
              v-model="form.data.birth_employees_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- 工伤保险基数 -->
        <el-col :span="24">
          <el-form-item prop="injury_base" label="工伤保险基数：">
            <el-input
              v-model="form.data.injury_base"
              clearable
              placeholder="请输入"
              ><span slot="suffix">元</span></el-input
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="injury_company_rate" label="单位占比：">
            <el-input
              v-model="form.data.injury_company_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="injury_employees_rate" label="员工占比：">
            <el-input
              v-model="form.data.injury_employees_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- 失业保险基数 -->
        <el-col :span="24">
          <el-form-item prop="unemployment_base" label="失业保险基数：">
            <el-input
              v-model="form.data.unemployment_base"
              clearable
              placeholder="请输入"
              ><span slot="suffix">元</span></el-input
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unemployment_company_rate" label="单位占比：">
            <el-input
              v-model="form.data.unemployment_company_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unemployment_employees_rate" label="员工占比：">
            <el-input
              v-model="form.data.unemployment_employees_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- 补充工伤险 -->
        <el-col :span="24">
          <el-form-item
            class="form-item-box"
            prop="extra_injury_base"
            label="补充工伤险基数："
          >
            <div slot="label" style="display: inline-grid">
              <div>补充工伤险基数：</div>
              <div style="color: red; font-size: 10px; margin-top: -10px">
                (缴纳工伤险此条生效)
              </div>
            </div>
            <el-input
              v-model="form.data.extra_injury_base"
              clearable
              placeholder="请输入"
              ><span slot="suffix">元</span></el-input
            >
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="extra_injury_company_rate" label="单位占比：">
              <el-input v-model="form.data.extra_injury_company_rate"
                ><span slot="suffix">%</span>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="extra_injury_employees_rate" label="员工占比：">
            <el-input
              :disabled="true"
              v-model="form.data.extra_injury_employees_rate"
            >
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- 大病医疗险 (鹰潭分公司不要大病医疗险)-->
        <template  v-if="icId != 2">
          <el-col :span="24">
            <el-form-item
              class="form-item-box"
              prop="illness_base"
              label="大病医疗险基数："
            >
              <div slot="label" style="display: inline-grid">
                <div>大病医疗险基数：</div>
                <div style="color: red; font-size: 10px; margin-top: -10px">
                  (缴纳医疗险此条生效)
                </div>
              </div>
              <el-input
                v-model="form.data.illness_base"
                clearable
                :disabled="false"
                ><span slot="suffix">元</span></el-input
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="illness_company_rate" label="单位占比：">
              <el-input
                v-model="form.data.illness_company_rate"
                ><span slot="suffix">%</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="illness_employees_rate" label="员工占比：">
              <el-input
                v-model="form.data.illness_employees_rate"
              >
                <span slot="suffix">%</span>
              </el-input>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { REG_EXP } from '@/util'
import common from "@/util";
import {getStorage} from '@/storage'
export default {
  name: "InsuranceLevelEdit",
  props:['invoiceCompanyOpt'],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司, 2:鹰潭）
      login_icId: getStorage('icId'),
      visible: false,
      isChange: false,
      loading: false,
      form: {
        loading: false,
        data: {
          invoice_company_id: '', // 开票单位
          name: "",
          endowment_base: "", // 养老保险
          endowment_company_rate: "16",
          endowment_employees_rate: "8",
          medical_base: "", // 医疗保险基数
          medical_company_rate: "6",
          medical_employees_rate: "2",
          birth_base: "", // 生育保险基数
          birth_company_rate: "0.8",
          birth_employees_rate: "0",
          injury_base: "", // 工伤保险基数
          injury_company_rate: "0.275",
          injury_employees_rate: "0",
          unemployment_base: "", // 失业保险基数
          unemployment_company_rate: "0.5",
          unemployment_employees_rate: "0.5",
          extra_injury_base: "0", // 补充工伤险
          extra_injury_company_rate: "0.33",
          extra_injury_employees_rate: "0",
          illness_base: "4860", // 大病医疗险
          illness_company_rate: "0.3",
          illness_employees_rate: "0.2",
        },
        rules: {
          invoice_company_id: [{ required: true, message: "请选择开票单位", trigger: "change" }],
          name: [{ required: true, message: "请输入基数档次", trigger: "change" }],
          endowment_base: [
            {
              required: true,
              message: "请输入保险基数",
              trigger: "change"
            },
            {
              pattern: REG_EXP.money,
              message: "请填写正确的金额格式，如：188.88",
              trigger: "change",
            }
          ],
          endowment_company_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          endowment_employees_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          medical_base: [
            { required: true,
              message: "请输入医疗保险基数",
              trigger: "change"
            },
            {
              pattern: REG_EXP.money,
              message: "请填写正确的金额格式，如：188.88",
              trigger: "change",
            }
          ],
          medical_company_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          medical_employees_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          birth_base: [
            { required: true,
              message: "请输入生育保险基数",
              trigger: "change"
            },
            {
              pattern: REG_EXP.money,
              message: "请填写正确的金额格式，如：188.88",
              trigger: "change",
            }
          ],
          birth_company_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          birth_employees_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          injury_base: [
            { required: true,
              message: "请输入工伤保险基数",
              trigger: "change"
            },
            {
              pattern: REG_EXP.money,
              message: "请填写正确的金额格式，如：188.88",
              trigger: "change",
            }
          ],
          injury_company_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          injury_employees_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          unemployment_base: [
            { required: true,
              message: "请输入失业保险基数",
              trigger: "change"
            },
            {
              pattern: REG_EXP.money,
              message: "请填写正确的金额格式，如：188.88",
              trigger: "change",
            }
          ],
          unemployment_company_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          unemployment_employees_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          extra_injury_base: [
            { required: true,
              message: "请输入补充工伤险基数",
              trigger: "change"
            },
            {
              pattern: REG_EXP.money,
              message: "请填写正确的金额格式，如：188.88",
              trigger: "change",
            }
          ],
          extra_injury_company_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
          extra_injury_employees_rate: [
            { required: true, message: "请输入", trigger: "change" },
          ],
        },
      },
      optOrgType: [],
      optOrg: [],
      options: [
        {
          value: 1,
          label: "企业",
        },
        {
          value: 2,
          label: "员工",
        },
      ],
      settlementType: [
        {
          value: 1,
          label: "当月薪资",
        },
        {
          value: 2,
          label: "上月薪资",
        },
      ],
    };
  },
  watch: {
    invoiceCompanyOpt(val) {
      this.invoiceCompanyOpt = val
    }
  },
  created() {
    // icId =2 鹰潭分公司
    this.setBase();

  },
  methods: {
    setBase() {
      if(this.icId == 2) { // 鹰潭分公司基数设置
        this.form.data.injury_company_rate = '0.2';  // 工伤保险(单位占比)
        this.form.data.illness_base = '5740'; // 大病医疗险基数
        this.form.data.medical_company_rate = '6.8'; // 医疗保险(单位占比)
        this.form.data.medical_employees_rate = '2'; // 医疗保险(员工占比)
        this.form.data.illness_company_rate = '3.6'; // 大病医疗险(单位占比)
        this.form.data.illness_employees_rate = '2.4'; // 大病医疗险(员工占比)
      } else if( this.icId == 5 ) { // 贵溪市人力资源基数设置
        this.form.data.injury_company_rate = '0.2';
        this.form.data.illness_base = '6098';
        this.form.data.medical_company_rate = '6.8';
        this.form.data.medical_employees_rate = '2';
        this.form.data.illness_company_rate = '3.6';
        this.form.data.illness_employees_rate = '2.4';
      }
    },
    // 获取五险详情
    getDetail(row) {
      this.isChange = true;
      this.$http
        .post("/admin/InsuranceLevel/detail", { id: row.id })
        .then((res) => {
          if (res.code === 1) {
            common.deepClone(this.form.data, res.data);
            this.icId = res.data.invoice_company_id;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    reset(done) {
      this.isChange = false;
      this.$refs.elFormDom.resetFields();
      done && done();
    },
    toggle(show) {
      this.visible = show;
      !show && this.reset();
    },
    // 选择开票公司
    selInvoiceChange(value) {
      this.icId = value
      if(this.icId == 2) {
        this.setBase(); // 鹰潭分公司基数设置
      } else if(this.icId == 5) { 
        this.setBase(); // 贵溪市人力资源发展有限公司
      } else {
        // 其他公司的默认
        this.form.data.injury_company_rate = '0.275' // 工伤保险(单位占比)
        this.form.data.illness_base = '4860'  // 大病医疗险基数
        this.form.data.medical_company_rate = '6' // 医疗保险(单位占比)
        this.form.data.medical_employees_rate = '2' // 医疗保险(员工占比)
        this.form.data.illness_company_rate = '0.3' // 大病医疗险(单位占比)
        this.form.data.illness_employees_rate = '0.2' // 大病医疗险(员工占比)
      }
    },
    // 提交
    confirm() {
      this.$refs.elFormDom.validate((valid) => {
        if (valid) {
          let apiUrl = !this.isChange
            ? "/admin/InsuranceLevel/create"
            : "/admin/InsuranceLevel/edit";
          // 不是总管理账号删掉开票公司id
          if(this.login_icId != 0) {
            delete this.form.data.invoice_company_id
          }
          // 判断开票单位是鹰潭分公司则去掉大病医疗险
          if(this.icId == 2 || this.form.data.invoice_company_id == 2) {
            delete this.form.data.illness_base
            delete this.form.data.illness_company_rate
            delete this.form.data.illness_employees_rate
          }
            
            console.log(this.form.data)
          this.form.loading = true;
          this.$http
            .post(apiUrl, this.form.data)
            .then((res) => {
              if (res.code === 1) {
                this.toggle(false);
                this.$emit("refresh");
                this.$message.success("操作成功！");
              } else {
                this.$message.error(res.msg);
              }
            })
            .finally(() => {
              this.form.loading = false;
            });
        }
      });
    },
    
    
    // 医疗保险基数失去焦点时判断如果为0，大病医疗险基数也为0
    blurInput(value) {
      console.log(value);
      if (value == 0) {
        this.form.data.illness_base = 0;
      } else if(this.icId == 2 ) {
        // 鹰潭分公司基数
        this.form.data.illness_base = 5740;
      } else if(this.icId == 5){
        // 贵溪分公司基数
        this.form.data.illness_base = 6098;
      } else {
        this.form.data.illness_base = 4860;
      }
    },

    // 验证金额只能输入数字和两位小数的
    checkAmount(value) {
      console.log(value);

      let checkPlan = this.form.data.endowment_base + "";
      checkPlan = checkPlan
        .replace(/[^\d.]/g, "") //清除数字和.以外的字符
        .replace(/\.{2,}/g, "") // 只保留第一个.清除多余的
        .replace(/^\./g, "") // 保证第一个为数字而不是.
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");

      if (checkPlan.indexOf(".") < 0 && checkPlan !== "") {
        checkPlan = parseFloat(checkPlan) + "";
      } else if (checkPlan.indexOf(".") >= 0) {
        checkPlan = checkPlan.replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3"); // 只能输入两个小数
      }
      this.form.data.endowment_base = checkPlan;
    },
  },
};
</script>

<style scoped>
.invoice_company ::v-deep .el-select {
  width: 100%;
}
</style>
